



































import { Component, Watch, Vue } from 'vue-property-decorator';
import { IUserState } from '../../store/modules/user/Interfaces';
import { namespace } from 'vuex-class';
import './scss/AccountActions.scss';

const NSProject = namespace('storeProject');
const NSUser = namespace('storeUser');

@Component({
    name: 'AccountActions',
    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class AccountActions extends Vue {

    @NSUser.Getter('userData') userData!: IUserState;

    userActionsShow: boolean = false;

    @Watch('userActionsShow')
    watchUserActionsShow() {
        if (!this.userActionsShow) {
            document.removeEventListener('click', this.checkClickOnAccountActions);
        }
    }

    get userNameFull() {
        return this.userData && this.userData.userInfo ? this.userData.userInfo.fullName : '';
    }

    get userNameShort() {
        if (this.userData && this.userData.userInfo && this.userData.userInfo.fullName) {
            const splitName = this.userData.userInfo.fullName.split(' ');
            if (splitName.length > 1) {
                return `${splitName[0][0]}${splitName[1][0]}`.toUpperCase();
            }
            return splitName[0][0].toUpperCase();
        }
        return '';
    }

    toggleAccountActions(): void {
        this.userActionsShow = !this.userActionsShow;
        if (this.userActionsShow) {
            document.addEventListener('click', this.checkClickOnAccountActions);
        }
    }

    checkClickOnAccountActions(e) {
        if (this.$el !== e.target && !e.target.closest('.account-actions')) {
            this.userActionsShow = false;
        }
    }

    changePassword() {
        this.$emit('change-password-confirmation');
        this.userActionsShow = false;
    }
}
